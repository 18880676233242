import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { ReactSVG } from 'react-svg';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import FilterModal from '../../components/FilterModal';
import Header from '../../components/Header';
import Card from '../../components/Card';
import ContactsModal from 'components/ContactsModal';

import filterIcon from '../../assets/filter.svg';

import {
  Filters,
  Agent,
  CitiesWithEnterprisesContacts,
} from '../../utils/InterFaces';
import {
  getStatusByNameOrCode,
  statusCodeToStatusLabel,
} from 'utils/callStatus';
import { useQuery } from 'react-query';
import { normalizeText } from 'utils/TextFormatter';
import { removeSpecialCaracteres, toTimestamp } from 'utils/numberUtils';
import { callStatusLabelToHexColor } from '../../utils/callStatusColors';

import api from '../../services/emeserver/api';
import authToken from '../../services/emeserver/authToken';

import reloadIcon from '../../assets/reload.svg';

import {
  BodyContainer,
  ClientsGrid,
  FiltersContainer,
  FiltersNavigation,
  //FilterStatusCall,
  FiltersModal,
  ClientsContainer,
  Pagination,
  NoClientDataContainer,
  LoadingContainer,
  PageStatusCall,
  FilterArea,
} from './styles';
import { new_calls_find } from 'services/requests';
import ReportsModal from 'components/ReportModal';
import ExportModal from 'components/ExportModal';

dayjs.extend(isBetween);

const Home: React.FC = () => {
  const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [initialLeads, setInitialLeads] = useState([]);
  const [dataStatus, setDataStatus] = useState([]);
  const [dataDiscarded, setDataDiscarded] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [perPage] = useState(16);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [year, setYear] = useState('');
  const [filters, setFilters] = useState<Filters | undefined>({});
  const [permissionLogIn, setpermissionLogIn] = useState({
    agent_id: selectedAgent.id,
    agent_permission: selectedAgent.permission,
    year: year,
  });

  const [agents, setAgents] = useState<Agent[]>();
  const [agentsNotAdmin, setAgentsNotAdmin] = useState<Agent[]>();
  const [agentSession, setAgentSession] = useState<Agent>();
  const [showPagination] = useState(true);
  const [total_calls, setTotal_calls] = useState(0);
  const [statusCodeSelected, setStatusCodeSelected] = useState('1');
  const [is_loading, setIs_loading] = useState(true);
  const [countQuantityLeadsPerStatus, setCountQuantityLeadsPerStatus] =
    useState({
      novo: 0,
      retorno: 0,
      aguardando: 0,
      em_atendimento: 0,
      com_interesse: 0,
      processo_de_compra: 0,
      descartado: 0,
      vendido: 0,
      sem_score: 0,
    });
  const [orderDataDesc, setOrderDataDesc] = useState(true);
  const [citiesAndEnterpriseName, setCitiesAndEnterpriseName] = useState([]);
  const [isShowModalContacts, setIsShowModalContacts] = useState(false);
  const [isShowModalReport, setIsShowModalReport] = useState(false);
  const [isShowModalExport, setIsShowModalExport] = useState(false);
  const [leadsExport, setLeadsExport] = useState([]);

  const [dateRefresh, setDateRefresh] = useState('');
  const [callsForRedistribute, setCallsForRedistribute] = useState(0);
  const [callsNewsNotification, setCallsNewsNotification] = useState(0);
  const [callsReturnNotification, setCallsReturnNotification] = useState(0);
  const [dataRefresh, setDataRefresh] = useState(false);
  const [dataRefreshDiscarded, setDataRefreshDiscarded] = useState(false);
  const [didLoad, setDidLoad] = useState<boolean>(false);
  const [amountClients, setAmountClients] = useState({
    news: 0,
    return: 0,
  });

  // const { isSuccess, isLoading, refetch, data: callsSuccess } = useQuery([ 'data', permissionLogIn.year ], () => new_calls_find(permissionLogIn);
  const {
    isSuccess,
    isLoading,
    refetch,
    data: callsSuccess,
  } = useQuery(['data', year], () => new_calls_find(permissionLogIn), {
    onSuccess: (result) => {
      setData([...result.clients, ...dataDiscarded]);
      result.callsForRedistribute !== null &&
        setCallsForRedistribute(
          result.callsForRedistribute ? result.callsForRedistribute.length : '',
        );
      setDataRefresh(!dataRefresh);
    },
    refetchInterval: 1000 * 60 * 1, // 1 minutes
    refetchIntervalInBackground: true, // Atualiza mesmo quando a janela no esta em foco
  });

  useEffect(() => {
    const run = async () => {
      const cities = await getEnterpriseRequest();
      await findAllAgentsAndSet();
      await findAllAgentsNotAdminAndSet();
      setCitiesAndEnterpriseName(cities);
    };
    run();
  }, []);

  useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      isSuccess &&
      !isLoading &&
      !showModal &&
      !didLoad
    ) {
      setData(callsSuccess.clients);
      setDidLoad(true);
    }
  }, [dataRefresh, dataRefreshDiscarded]);

  useEffect(() => {
    if (data && data.length > 0 && isSuccess && !isLoading && !showModal) {
      const amountNews = data.filter((item) => item.status === '1');
      const amountReturn = data.filter((item) => item.status === '9');
      setAmountClients({
        news: amountNews.length,
        return: amountReturn.length,
      });

      if (dateRefresh !== '' && amountNews.length > amountClients.news) {
        setCallsNewsNotification(
          amountNews.length - amountClients.news + callsNewsNotification,
        );
      }

      if (dateRefresh !== '' && amountReturn.length > amountClients.return) {
        setCallsReturnNotification(
          amountReturn.length - amountClients.return + callsReturnNotification,
        );
      }

      fetchLeads(filters, statusCodeSelected);
    }
  }, [dataRefresh, dataRefreshDiscarded]);

  const filterCallBackByAgentId = async (agent_id: number) => {
    //this.fetchLeads(undefined);
  };

  /* const refreshLeads = () => {
    setIs_loading(true);

    fetchLeads(undefined);
  } */

  const filterCallbackResults = (filters: Filters) => {
    setShowFilterModal(false);
    setIs_loading(true);
    setOrderDataDesc(true);
    setFilters(filters);

    fetchLeads(filters);
  };

  const findAllAgentsAndSet = async () => {
    const res = await api.get('/agents', {
      headers: {
        Authorization: authToken.token,
      },
    });

    const agent = res.data.agents.find(
      (agent: any) => agent.email === sessionStorage.getItem('user'),
    );

    setAgents(res.data.agents);
    setAgentSession(agent);
  };

  const findAllAgentsNotAdminAndSet = async () => {
    const res = await api.get('/agents/not-admin', {
      headers: {
        Authorization: authToken.token,
      },
    });

    const agents = res.data.agents;
    let agentsList;
    if (selectedAgent.permission !== 'admin-super') {
      agentsList = agents.filter((agents) => {
        return agents.group_dashboard === selectedAgent.group_dashboard;
      });
    } else {
      agentsList = agents;
    }
    setAgentsNotAdmin(agentsList);
  };

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;

    const offsetPage = selectedPage * perPage;

    setCurrentPage(selectedPage);
    setPageData(dataStatus.slice(offsetPage, offsetPage + perPage));
  };

  const handleStatusClick = (
    status_code: string,
    data = initialLeads,
    currentPage = 0,
  ) => {
    let filteredLeadsData;
    // if (status_code === "10") {
    //   filteredLeadsData = getDataFilteredScoreZero(data)
    // }
    // else {
    filteredLeadsData = getDataFilteredByStatus(status_code, data);
    // }
    const offsetPage = currentPage * perPage;

    const slice = filteredLeadsData.slice(offsetPage, offsetPage + perPage);

    const pageCount = Math.ceil(filteredLeadsData.length / perPage);

    setPageData(slice);
    setDataStatus(filteredLeadsData);
    setStatusCodeSelected(status_code);
    setPageCount(pageCount);
    setCurrentPage(currentPage);
  };

  const handleFilterScoreClick = (score: string) => {
    let filter: Filters;
    if (score === filters.score) {
      filter = {
        ...filters,
        score: '',
      };
    } else {
      filter = {
        ...filters,
        score,
      };
    }

    setFilters(filter);

    fetchLeads(filter);
  };

  const handleCloseFilterModal = async () => {
    setShowFilterModal(false);
  };

  const handleShowFiltersModal = () => {
    setShowFilterModal(true);
  };

  const handleReloadClientsWhenInfoUpdated = async () => {
    setIs_loading(true);

    await refetch();
    setIs_loading(false);
  };

  const fetchLeads = (
    filtersData: Filters | undefined,
    currentStatus?: string,
  ) => {
    const leadsWithCallRes = getFilters(filtersData);

    if (leadsWithCallRes) {
      const leadsData = leadsWithCallRes;

      const quantityPerStatus = getQuantityPerStatus(leadsData);

      const filterStatus = Object.entries(quantityPerStatus).filter(
        (item) => item[1] > 0,
      );

      const status = currentStatus
        ? [currentStatus]
        : filterStatus.length > 0
        ? getStatusByNameOrCode(String(filterStatus[0][0]))
        : ['1'];

      const dateAtual = moment().format('DD/MM/YYYY H:m');

      setInitialLeads(leadsData);

      if (isSuccess && !isLoading) setTotal_calls(leadsData.length);

      setIs_loading(false);
      setDateRefresh(dateAtual);

      handleStatusClick(String(status[0]), leadsData, currentPage);
      setIs_loading(false);

      return leadsData;
    }
  };

  const getFilters = (filtersData: Filters) => {
    if (!data) {
      return;
    }

    const {
      agent_id,
      city,
      enterprise,
      name,
      phone,
      cpf,
      email,
      call_type,
      multipleCalls,
      isdiscard,
      justifyDiscard,
      origin,
      conversionChannel,
      updateDays,
      group_dashboard,
      call_date_from,
      call_date_to,
      call_date_update_from,
      call_date_update_to,
      call_date_sold_from,
      call_date_sold_to,
      monthly_income_from,
      monthly_income_to,
      score,
    } = filtersData;

    let clients = data;

    if (agent_id && agent_id > 0) {
      clients = clients.filter(
        (lead) => String(lead.agent_id) === String(agent_id),
      );
    }

    if (name && name !== '') {
      const nameNormalized = normalizeText(name);
      clients = clients.filter(
        (lead) =>
          normalizeText(lead.full_name).includes(nameNormalized) ||
          normalizeText(lead.proposal_owner).includes(nameNormalized),
      );
    }

    if (phone && phone !== '') {
      const phoneFormatted = removeSpecialCaracteres(phone);
      clients = clients.filter(
        (lead) =>
          removeSpecialCaracteres(lead.phone_number) === phoneFormatted ||
          removeSpecialCaracteres(lead.phone_number_2) === phoneFormatted,
      );
    }

    if (cpf && cpf !== '') {
      const cpfFormatted = removeSpecialCaracteres(cpf);
      clients = clients.filter((lead) =>
        removeSpecialCaracteres(lead.cpf).includes(cpfFormatted),
      );
    }

    if (
      group_dashboard &&
      group_dashboard !== '' &&
      group_dashboard !== 'hmgeral'
    ) {
      clients = clients.filter((lead) => {
        return lead.group_dashboard === group_dashboard;
      });
    }

    if (multipleCalls && multipleCalls !== '') {
      clients = clients.filter((lead) => {
        return String(lead.multiple_calls) === multipleCalls;
      });
    }

    if (origin && origin !== '') {
      if (origin === 'landing-page') {
        clients = clients.filter((lead) => {
          return lead.origin.includes('lp') === true;
        });
      } else if (origin === 'site') {
        clients = clients.filter(
          (lead) => origin === lead.origin || lead.origin === 'Site',
        );
      } else {
        clients = clients.filter((lead) => {
          return origin === lead.origin;
        });
      }
    }

    if (conversionChannel && conversionChannel !== '') {
      if (conversionChannel === 'lp-form') {
        clients = clients.filter(
          (lead) =>
            conversionChannel === lead.conversion_channel ||
            lead.conversion_channel === 'form' ||
            lead.conversion_channel === 'formLead',
        );
      } else if (conversionChannel === 'leadForm') {
        clients = clients.filter(
          (lead) =>
            conversionChannel === lead.conversion_channel ||
            lead.conversion_channel === 'leadform',
        );
      } else {
        clients = clients.filter((lead) => {
          return conversionChannel === lead.conversion_channel;
        });
      }
    }

    if (isdiscard && isdiscard !== '') {
      clients = clients.filter((lead) => {
        if (isdiscard === 'true') {
          if (justifyDiscard === '') {
            return String(lead.isdiscard) === isdiscard && lead.status === '4';
          } else {
            return (
              String(lead.isdiscard) === isdiscard &&
              lead.status === '4' &&
              justifyDiscard === lead.justification
            );
          }
        } else {
          return String(lead.isdiscard) === 'null' && lead.status === '4';
        }
      });
    }

    if (updateDays && updateDays !== 0) {
      if (Number(updateDays) === 1) {
        clients = clients.filter((lead) => {
          return (
            Number(
              (
                (new Date().getTime() -
                  new Date(lead.call_date_update_original).getTime()) /
                86400000
              ).toFixed(0),
            ) >= 1 &&
            Number(
              (
                (new Date().getTime() -
                  new Date(lead.call_date_update_original).getTime()) /
                86400000
              ).toFixed(0),
            ) <= 2
          );
        });
      } else if (Number(updateDays) === 2) {
        clients = clients.filter((lead) => {
          return (
            Number(
              (
                (new Date().getTime() -
                  new Date(lead.call_date_update_original).getTime()) /
                86400000
              ).toFixed(0),
            ) >= 3 &&
            Number(
              (
                (new Date().getTime() -
                  new Date(lead.call_date_update_original).getTime()) /
                86400000
              ).toFixed(0),
            ) <= 10
          );
        });
      } else if (Number(updateDays) === 3) {
        clients = clients.filter((lead) => {
          return (
            Number(
              (
                (new Date().getTime() -
                  new Date(lead.call_date_update_original).getTime()) /
                86400000
              ).toFixed(0),
            ) > 10
          );
        });
      }
    }

    if (email && email !== '') {
      const emailLc = email.toLowerCase();
      let clientsFilter = clients.filter(
        (lead) => lead.email && lead.email.toLowerCase().includes(emailLc),
      );
      if (clientsFilter.length === 0) {
        clientsFilter = clients.filter(
          (lead) =>
            lead.email_2 && lead.email_2.toLowerCase().includes(emailLc),
        );
      }
      clients = clientsFilter;
    }

    if (
      monthly_income_from !== undefined &&
      monthly_income_from !== null &&
      Number(monthly_income_from) !== 0 &&
      monthly_income_to !== undefined &&
      Number(monthly_income_to) !== 0 &&
      monthly_income_to !== null
    ) {
      clients = clients.filter(
        (lead) =>
          lead.monthly_income > monthly_income_from &&
          lead.monthly_income < monthly_income_to,
      );
    }

    if (call_type && call_type !== '') {
      clients = clients.filter((lead) => lead.call_type_original === call_type);
    }

    if (city && city !== '') {
      const cityNormalized = normalizeText(city);
      clients = clients.filter((lead) =>
        normalizeText(lead.city).includes(cityNormalized),
      );
    }

    if (enterprise && enterprise !== '') {
      const enterpriseNormalized = normalizeText(enterprise);
      clients = clients.filter((lead) =>
        normalizeText(lead.enterprise_name).includes(enterpriseNormalized),
      );
    }
    //Buscar pela data do Card
    if (call_date_from !== undefined) {
      if (call_date_to !== undefined) {
        const callDateTo = call_date_to.setHours(23, 59, 59) / 1000;
        const callDateFrom = toTimestamp(call_date_from);

        clients = clients.filter(
          (lead) =>
            toTimestamp(lead.call_date_original) >= callDateFrom &&
            toTimestamp(lead.call_date_original) <= callDateTo,
        );
      } else {
        const actualDate = new Date().setHours(23, 59, 59) / 1000;
        const callDateFrom = toTimestamp(call_date_from);

        clients = clients.filter(
          (lead) =>
            toTimestamp(lead.call_date_original) >= callDateFrom &&
            toTimestamp(lead.call_date_original) <= actualDate,
        );
      }
    }
    //Busca pela data do Update
    if (call_date_update_from !== undefined) {
      if (call_date_update_to !== undefined) {
        const callDateTo = call_date_update_to.setHours(23, 59, 59) / 1000;
        const callDateFrom = toTimestamp(call_date_update_from);

        clients = clients.filter(
          (lead) =>
            toTimestamp(lead.call_date_update_original) >= callDateFrom &&
            toTimestamp(lead.call_date_update_original) <= callDateTo,
        );
      } else {
        const actualDate = new Date().setHours(23, 59, 59) / 1000;
        const callDateFrom = toTimestamp(call_date_update_from);

        clients = clients.filter(
          (lead) =>
            toTimestamp(lead.call_date_update_original) >= callDateFrom &&
            toTimestamp(lead.call_date_update_original) <= actualDate,
        );
      }
    }

    //Busca pela data de Venda
    if (call_date_sold_from !== undefined) {
      if (call_date_sold_to !== undefined) {
        const callDateTo = call_date_sold_to.setHours(23, 59, 59) / 1000;
        const callDateFrom = toTimestamp(call_date_sold_from);

        clients = clients.filter(
          (lead) =>
            toTimestamp(lead.call_date_sold_original) >= callDateFrom &&
            toTimestamp(lead.call_date_sold_original) <= callDateTo,
        );
      } else {
        const actualDate = new Date().setHours(23, 59, 59) / 1000;
        const callDateFrom = toTimestamp(call_date_sold_from);

        clients = clients.filter(
          (lead) =>
            toTimestamp(lead.call_date_sold_original) >= callDateFrom &&
            toTimestamp(lead.call_date_sold_original) <= actualDate,
        );
      }
    }

    if (score) {
      switch (score) {
        case 'high':
          clients = clients.filter((lead) => lead.hm_score > 70);
          break;
        case 'medium':
          clients = clients.filter(
            (lead) => lead.hm_score > 30 && lead.hm_score <= 70,
          );
          break;
        case 'low':
          clients = clients.filter((lead) => lead.hm_score <= 30);
          break;
        case 'zerado':
          clients = clients.filter((lead) => {
            return (
              lead.hm_score === null ||
              lead.hm_score === 0 ||
              lead.hm_score === 1 ||
              lead.status === 10
            );
          });
          break;
        default:
          break;
      }
    }

    clients = clients.sort((clientA, clientB) =>
      sortClientCards(clientA, clientB),
    );

    return clients;
  };

  const checkDateIsBetweenTwoDates = (
    date: Date | undefined | Dayjs,
    date_from: Date | string | Dayjs,
    date_to: Date | string | Dayjs,
  ) => {
    if (!date) {
      return false;
    }

    return dayjs(date).isBetween(date_from, date_to);
  };

  const sortClientCards = (clientA: any, clientB: any): number => {
    const dateA = new Date(clientA.call_date_original);
    const dateB = new Date(clientB.call_date_original);

    dateA.setHours(0, 0, 0, 0);
    dateB.setHours(0, 0, 0, 0);

    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;

    if (clientA.hm_score > clientB.hm_score) return -1;
    if (clientA.hm_score < clientB.hm_score) return 1;

    return 0;
  };

  const getEnterpriseRequest = async () => {
    const { data } = await api.get(
      '/dashboard/cities/list/' + selectedAgent.group_dashboard,
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    const citiesData: CitiesWithEnterprisesContacts[] = data.response_data;

    return citiesData;
  };

  const orderByDataAscDesc = () => {
    let dataFiltered = getDataFilteredByOrdenation();

    setInitialLeads(dataFiltered);

    handleStatusClick(statusCodeSelected, dataFiltered);
  };

  const clearOrdination = () => {
    setFilters({ ...filters, score: '' });
    setOrderDataDesc(true);

    fetchLeads({ ...filters, score: '' });
  };

  const getQuantityPerStatus = (
    leadsData: {
      status: string;
      hm_score: number;
    }[],
  ) => {
    const count = {
      novo: 0,
      retorno: 0,
      aguardando: 0,
      em_atendimento: 0,
      com_interesse: 0,
      processo_de_compra: 0,
      descartado: 0,
      vendido: 0,
      sem_score: 0,
    };

    leadsData.map((item) => {
      switch (item.status) {
        case '1':
          count.novo++;
          break;
        case '9':
          count.retorno++;
          break;
        case '2':
          count.em_atendimento++;
          break;
        case '6':
          count.aguardando++;
          break;
        case '7':
          count.com_interesse++;
          break;
        case '8':
          count.processo_de_compra++;
          break;
        case '3':
          count.vendido++;
          break;
        case '4':
          count.descartado++;
          break;
        case '10':
          count.sem_score++;
          break;
      }
      // switch (item.hm_score) {
      //   case 0:
      //     count.sem_score++;
      //     break;
      // }
    });

    setCountQuantityLeadsPerStatus(count);

    return count;
  };

  const getDataFilteredByStatus = (
    status_code: string,
    data: { status: string; hm_score: number }[],
  ) => {
    const dataFiltered = data;

    const filteredLeadsData = dataFiltered.filter(
      (lead) => lead.status === status_code,
    );

    getQuantityPerStatus(dataFiltered);

    setInitialLeads(dataFiltered);

    return filteredLeadsData;
  };

  const getDataFilteredScoreZero = (data) => {
    const dataFiltered = data;

    const filteredLeadsData = dataFiltered.filter((lead) => {
      return (
        lead.hm_score === 0 || lead.hm_score === null || lead.status === '10'
      );
    });

    setInitialLeads(dataFiltered);

    return filteredLeadsData;
  };

  const getDataFilteredByOrdenation = () => {
    let dataFiltered = initialLeads;

    if (orderDataDesc) {
      dataFiltered = dataFiltered.sort(
        (a, b) =>
          new Date(a.call_date_update_original).getTime() -
          new Date(b.call_date_update_original).getTime(),
      );
    } else {
      dataFiltered = dataFiltered.sort(
        (a, b) =>
          new Date(b.call_date_update_original).getTime() -
          new Date(a.call_date_update_original).getTime(),
      );
    }

    return dataFiltered;
  };

  const handleShowContactModal = () => {
    setIsShowModalContacts(!isShowModalContacts);
  };

  const handleShowReportModal = () => {
    setIsShowModalReport(!isShowModalReport);
  };

  const handleShowExportModal = () => {
    const leads = initialLeads.map((leads) => {
      const leadsExport = {
        name: leads.full_name,
        phone_number: leads.phone_number,
        email: leads.email,
        cpf: leads.cpf,
        origin: leads.origin,
        status: statusCodeToStatusLabel(leads.status),
        enterprise: leads.enterprise_name,
        hmScore: leads.hm_score,
        agentId: leads.agent_id,
        agentName: leads.agent_name,
        callDate: leads.call_date,
      };
      return leadsExport;
    });
    setLeadsExport(leads);
    setIsShowModalExport(!isShowModalExport);
  };

  const handleChangeYear = (year: any) => {
    setYear(year);
    setpermissionLogIn({
      agent_permission: permissionLogIn.agent_permission,
      agent_id: permissionLogIn.agent_id,
      year,
    });
    setTotal_calls(0);
    setCountQuantityLeadsPerStatus({
      novo: 0,
      retorno: 0,
      aguardando: 0,
      em_atendimento: 0,
      com_interesse: 0,
      processo_de_compra: 0,
      descartado: 0,
      vendido: 0,
      sem_score: 0,
    });
    setCallsNewsNotification(0);
    setCallsReturnNotification(0);
    setIs_loading(true);
  };

  const yearsUpdate = [
    { label: '2023', value: '2023' },
    { label: '2022', value: '2022' },
    { label: '2021', value: '2021' },
    { label: '2020', value: '2020' },
  ];

  return (
    <>
      <Header
        callbackFunction={filterCallBackByAgentId}
        total_calls={total_calls}
        //refreshLeads={() =>refreshLeads()}
        dateAtual={dateRefresh as string}
        callsForRedistribute={callsForRedistribute}
        handleShowContactModal={handleShowContactModal}
        handleShowReportModal={handleShowReportModal}
        handleShowExportModal={handleShowExportModal}
      />

      <FilterArea
        buttonFilterScore={filters.score}
        ordenationFilterData={orderDataDesc}
      >
        <div>
          {/* <div className="yearSelect">
            <Select
              onChange={(e) => {
                handleChangeYear(e.value);
              }}
              style={{ marginTop: '1rem', height: '45px' }}
              maxMenuHeight={230}
              placeholder={permissionLogIn.year}
              options={yearsUpdate.map((item) => ({
                value: String(item.value),
                label: item.label,
              }))}
            />
          </div> */}
          <div className="filters">
            <div className="clearFilters">
              <label>Reordenar</label>
              <span>
                <button
                  onClick={() => {
                    clearOrdination();
                  }}
                >
                  <ReactSVG src={reloadIcon} />
                </button>
              </span>
            </div>
            <div className="ordenation">
              <label>Ordenar por</label>
              <span>
                <button
                  onClick={() => {
                    setOrderDataDesc(!orderDataDesc);
                    orderByDataAscDesc();
                  }}
                  className="desc"
                >
                  Mais recente
                </button>
                <button
                  onClick={() => {
                    setOrderDataDesc(!orderDataDesc);
                    orderByDataAscDesc();
                  }}
                  className="asc"
                >
                  Mais antigo
                </button>
              </span>
            </div>
            <div className="filterScore">
              <label>Leads por score</label>
              <span>
                <span
                  className="high"
                  data-hover={
                    filters.score === 'high'
                      ? 'Limpar filtro'
                      : 'Exibir score alto'
                  }
                >
                  <button
                    onClick={() => {
                      handleFilterScoreClick('high');
                    }}
                    className="high"
                  >
                    Alto
                  </button>
                </span>
                <span
                  className="medium"
                  data-hover={
                    filters.score === 'medium'
                      ? 'Limpar filtro'
                      : 'Exibir score médio'
                  }
                >
                  <button
                    onClick={() => {
                      handleFilterScoreClick('medium');
                    }}
                    className="medium"
                  >
                    Médio
                  </button>
                </span>
                <span
                  className="low"
                  data-hover={
                    filters.score === 'low'
                      ? 'Limpar filtro'
                      : 'Exibir score baixo'
                  }
                >
                  <button
                    onClick={() => {
                      handleFilterScoreClick('low');
                    }}
                    className="low"
                  >
                    Baixo
                  </button>
                </span>
                <span
                  className="zerado"
                  data-hover={
                    filters.score === 'zerado'
                      ? 'Limpar filtro'
                      : 'Exibir score zerado'
                  }
                >
                  <button
                    onClick={() => {
                      handleFilterScoreClick('zerado');
                    }}
                    className="zerado"
                  >
                    Zerado
                  </button>
                </span>
              </span>
            </div>
          </div>
          <div className="buttonOpenModal">
            <FiltersModal
              onClick={() => handleShowFiltersModal()}
              disabled={is_loading}
              is_loading={is_loading}
            >
              <ReactSVG src={filterIcon} className="filter-icon" />
            </FiltersModal>
          </div>
        </div>
      </FilterArea>
      <BodyContainer>
        <FiltersContainer>
          <FiltersNavigation>
            <nav>
              <PageStatusCall
                filterSelected={statusCodeSelected === '1'}
                href="#/novos"
                onClick={() => {
                  setCallsNewsNotification(0);
                  handleStatusClick('1');
                }}
                backgroundHexColor={callStatusLabelToHexColor('Novos')}
              >
                <span>
                  <div>
                    <label>Novos</label>
                    <label>{countQuantityLeadsPerStatus.novo}</label>
                    <label
                      style={{
                        ...(callsNewsNotification === 0
                          ? { display: 'none' }
                          : ''),
                      }}
                      className={
                        statusCodeSelected === '1'
                          ? 'new-card white'
                          : 'new-card red'
                      }
                    >
                      {callsNewsNotification}
                    </label>
                  </div>
                  <span className="bar" />
                </span>
              </PageStatusCall>
              <PageStatusCall
                filterSelected={statusCodeSelected === '9'}
                href="#/retorno"
                onClick={() => {
                  setCallsReturnNotification(0);
                  handleStatusClick('9');
                }}
                backgroundHexColor={callStatusLabelToHexColor('Retorno')}
              >
                <span>
                  <div>
                    <label>Retorno</label>
                    <label>{countQuantityLeadsPerStatus.retorno}</label>
                    <label
                      style={{
                        ...(callsReturnNotification === 0
                          ? { display: 'none' }
                          : ''),
                      }}
                      className={
                        statusCodeSelected === '9'
                          ? 'new-card white'
                          : 'new-card red'
                      }
                    >
                      {callsReturnNotification}
                    </label>
                  </div>
                  <span className="bar" />
                </span>
              </PageStatusCall>

              <PageStatusCall
                filterSelected={statusCodeSelected === '10'}
                href="#/semScore"
                onClick={() => {
                  handleStatusClick('10');
                }}
                backgroundHexColor={callStatusLabelToHexColor('Sem Score')}
              >
                <span>
                  <div>
                    <label>Sem Score</label>
                    <label>{countQuantityLeadsPerStatus.sem_score}</label>
                  </div>
                  <span className="bar" />
                </span>
              </PageStatusCall>

              <PageStatusCall
                filterSelected={statusCodeSelected === '6'}
                href="#/aguardando"
                onClick={() => handleStatusClick('6')}
                backgroundHexColor={callStatusLabelToHexColor('Aguardando')}
              >
                <span>
                  <div>
                    <label>Aguardando</label>
                    <label>{countQuantityLeadsPerStatus.aguardando}</label>
                  </div>
                  <span className="bar" />
                </span>
              </PageStatusCall>

              <PageStatusCall
                filterSelected={statusCodeSelected === '2'}
                href="#/em_atendimento"
                onClick={() => handleStatusClick('2')}
                backgroundHexColor={callStatusLabelToHexColor('Em atendimento')}
              >
                <span>
                  <div>
                    <label>Em atendimento</label>
                    <label>{countQuantityLeadsPerStatus.em_atendimento}</label>
                  </div>
                  <span className="bar" />
                </span>
              </PageStatusCall>

              <PageStatusCall
                filterSelected={statusCodeSelected === '7'}
                href="#/com-interesse"
                onClick={() => handleStatusClick('7')}
                backgroundHexColor={callStatusLabelToHexColor('Com interesse')}
              >
                <span>
                  <div>
                    <label>Com interesse</label>
                    <label>{countQuantityLeadsPerStatus.com_interesse}</label>
                  </div>
                  <span className="bar" />
                </span>
              </PageStatusCall>

              <PageStatusCall
                filterSelected={statusCodeSelected === '8'}
                href="#/processo-de-compra"
                onClick={() => handleStatusClick('8')}
                backgroundHexColor={callStatusLabelToHexColor(
                  'Processo de compra',
                )}
              >
                <span>
                  <div>
                    <label>Processo de compra</label>
                    <label>
                      {countQuantityLeadsPerStatus.processo_de_compra}
                    </label>
                  </div>
                  <span className="bar" />
                </span>
              </PageStatusCall>

              <PageStatusCall
                filterSelected={statusCodeSelected === '4'}
                href="#/descartados"
                onClick={() => handleStatusClick('4')}
                backgroundHexColor={callStatusLabelToHexColor('Descartados')}
              >
                <span>
                  <div>
                    <label>Descartados</label>
                    <label>{countQuantityLeadsPerStatus.descartado}</label>
                  </div>
                  <span className="bar" />
                </span>
              </PageStatusCall>

              <PageStatusCall
                filterSelected={statusCodeSelected === '3'}
                href="#/vendidos"
                onClick={() => handleStatusClick('3')}
                backgroundHexColor={callStatusLabelToHexColor('Vendidos')}
              >
                <span>
                  <div>
                    <label>Vendidos</label>
                    <label>{countQuantityLeadsPerStatus.vendido}</label>
                  </div>
                  <span className="bar" />
                </span>
              </PageStatusCall>
            </nav>
          </FiltersNavigation>
        </FiltersContainer>

        <ClientsContainer>
          {is_loading ? (
            <LoadingContainer>
              <Spinner animation="border" role="status" />
            </LoadingContainer>
          ) : (
            <>
              {pageData !== undefined && pageData.length > 0 ? (
                <>
                  <ClientsGrid>
                    {pageData.map((pd, i) => (
                      <Card
                        key={i}
                        id={pd.client_id}
                        score={pd.hm_score}
                        color={pd.hm_score_color}
                        nick={pd.nick_name}
                        full_name={pd.full_name}
                        callId={pd.call_id}
                        enterprise={pd.enterprise_name}
                        callType={pd.call_type}
                        last_call_status={pd.status}
                        origin={pd.origin}
                        date={pd.call_date}
                        parentCallback={handleReloadClientsWhenInfoUpdated}
                        agents={agentsNotAdmin ? agentsNotAdmin : []}
                        agent_name={pd.agent_id}
                        multiples_calls={pd.multiples_calls}
                        is_most_recent={pd.is_most_recent}
                        automation_waiting={pd.automation_waiting}
                        justification={pd.justification}
                        dateUpdated={pd.call_date_update_original}
                        campaign_participant={pd.campaign_participant}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        call_group={pd.group_dashboard}
                      />
                    ))}
                  </ClientsGrid>

                  {showPagination && (
                    <Pagination>
                      <ReactPaginate
                        previousLabel={''}
                        nextLabel={''}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={5}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        initialPage={currentPage}
                        forcePage={currentPage}
                      />
                    </Pagination>
                  )}
                </>
              ) : (
                <NoClientDataContainer>
                  <span>
                    Nenhum resultado encontrado para os filtros escolhidos.
                  </span>
                </NoClientDataContainer>
              )}
            </>
          )}
        </ClientsContainer>

        <FilterModal
          agents={agentsNotAdmin ? agentsNotAdmin : []}
          parentCallback={filterCallbackResults}
          show={showFilterModal}
          click={() => handleCloseFilterModal()}
          onHide={() => handleCloseFilterModal()}
          onClose={() => handleCloseFilterModal()}
          agent_id={agentSession ? agentSession.id : 0}
          statusSelected={statusCodeSelected}
        />

        <ContactsModal
          agents={agentsNotAdmin}
          listEnterprise={citiesAndEnterpriseName}
          handleShowContactModal={handleShowContactModal}
          showModalContacts={isShowModalContacts}
        />

        <ReportsModal
          agents={agentsNotAdmin}
          listEnterprise={citiesAndEnterpriseName}
          handleShowReportModal={handleShowReportModal}
          showModalReports={isShowModalReport}
        />

        <ExportModal
          agents={agentsNotAdmin}
          leads={leadsExport}
          listEnterprise={citiesAndEnterpriseName}
          handleShowExportModal={handleShowExportModal}
          showModalExports={isShowModalExport}
        />
      </BodyContainer>
    </>
  );
};

export default Home;
