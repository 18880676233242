import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import IntlCurrencyInput from 'react-intl-currency-input';
import { IMaskInput } from '@mirco312312/react-imask';
import { toast, ToastContainer } from 'react-toastify';

import api from '../../services/emeserver/api';

import authToken from '../../services/emeserver/authToken';

import { tz } from 'moment-timezone';

import CallCommentary from './CallCommentary';
import iCallCommentary from '../../dtos/iCallCommentary';

import { statusCodeToStatusLabel } from '../../utils/callStatus';
import { removeSpecialCaractersFromPhoneNumber } from 'utils/numberUtils';

import {
  callStatusCodeToHexColor,
  callStatusCodeToIndex,
} from '../../utils/callStatusColors';

import {
  ModalStyle,
  Score,
  Confidence,
  Credit,
  BackContainer,
  StatusContainer,
  ID,
  Information,
  Whatsapp,
  PhoneContainer,
  PhoneVerified,
  CallsContainer,
  Attendance,
  InputData,
  AddCommentary,
  ModalFinancing,
  TimeLine,
} from './styles';

import {
  ICalls,
  IClient,
  Modal,
  IScore,
  ICallsLogs,
  Agent,
} from '../../utils/InterFaces';

import date_icon from '../../assets/date.svg';
import arrow_icon from '../../assets/arrow-left.svg';
import calc_icon from '../../assets/calc.svg';
import whatsapp_icon from '../../assets/whatsapp.svg';
import check_icon from '../../assets/check.svg';
import help_icon from '../../assets/help-circle.svg';
import editIcon from '../../assets/edit.svg';

import { capitalizeFirstLetter } from 'utils/TextFormatter';
import { dataItens } from 'components/shared/dataItems';
import { SelectEnterprise } from 'components/SelectEnterprise';
import { Chrono } from 'react-chrono';
import { cpfValidate } from 'utils/numberUtils';
import moment from 'moment';

const ModalElement: React.FC<Modal> = (props) => {
  const [income, setIncome] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedAgent, setSelectedAgent] = useState('');
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [incomeUpdated, setIncomeUpdated] = useState(false);
  const [agentUpdated, setAgentUpdated] = useState(false);
  const [commentaries, setCommentaries] = useState<iCallCommentary[]>([]);
  const [showAddCommentary, setShowAddCommentary] = useState(true);
  const [updatedCommentaries, setUpdatedCommentaries] = useState(false);

  const [errorsColors, setErrorsColors] = useState('');
  const [errorsColorsSelect, setErrorsColorsSelect] = useState('');

  const [fullName, setFullName] = useState('');
  const [cpf, setCpf] = useState('');
  const [showEditFullName, setShowEditFullName] = useState(true);
  const [showEditCPF, setShowEditCPF] = useState(true);
  const [nickName, setNickName] = useState('');
  const [showEditNickName, setShowEditNickName] = useState(true);
  const [proposalOwner, setProposalOwner] = useState('');
  const [showEditProposalOwner, setShowEditProposalOwner] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [showEditPhoneNumber, setShowEditPhoneNumber] = useState(true);
  const [email, setEmail] = useState('');
  const [showEditEmail, setShowEditEmail] = useState(true);
  const [phoneNumber2, setPhoneNumber2] = useState('');
  const [showEditPhoneNumber2, setShowEditPhoneNumber2] = useState(true);
  const [email2, setEmail2] = useState('');
  const [showEditEmail2, setShowEditEmail2] = useState(true);
  const [updatedData, setUpdatedData] = useState(false);
  const [discardJustification, setDiscardJustification] = useState('');
  const [changeDiscardJusticaiton, setChangeDiscardJustication] =
    useState(false);
  const [changeDateVenda, setChangeDateVenda] = useState(false);
  const [dataSimulation, setDataSimulation] = useState<any>({});
  const [showModalSimulation, setShowModalSimulation] = useState(false);
  const [isUpdateEnterprise, setIsUpdateEnterprise] = useState(false);
  const [dataVenda, setDataVenda] = useState('');
  const [errorsTelefone, setErrorsTelefone] = useState('');
  const [errorsEmail, setErrorsEmail] = useState('');
  const [errorsCPF, setErrorsCPF] = useState('');
  const [currentIndex, setCurrentIndex] = useState('');

  const [initialCalls, setInitialCalls] = useState([]);
  const [errorScore, setErrorScore] = useState('');
  const [classScore, setClassScore] = useState('');
  const [callsLogs, setCallsLogs] = useState([]);
  const [title, setTitle] = useState([]);
  const [status, setStatus] = useState('');
  const [selectAgent, setSelectAgent] = useState<Agent[]>([]);

  const calls: ICalls[] = props.data.calls;
  const client: IClient = props.data.client;
  const scores: IScore = props.data.scores;

  const calls_logs: ICallsLogs[] = props.calls_logs;
  const initialStatus = props.last_call_status;

  const permission = sessionStorage.getItem('permission');

  useEffect(() => {
    if (initialStatus !== undefined) setSelectedStatus(initialStatus);
  }, [initialStatus]);

  useEffect(() => {
    let agent;
    if (permission === 'admin-super') {
      agent = props.agents.filter(
        (agents) => agents.group_dashboard === props.call_group,
      );
      setSelectAgent(agent);
    } else {
      agent = props.agents;
      setSelectAgent(agent);
    }
  }, []);

  // Values should be only date
  let items = [
    { title: 'Novo', thisStatus: '1', visible: false },
    { title: 'Retorno', thisStatus: '9', visible: false },
    { title: 'Aguardando', thisStatus: '6', visible: false },
    { title: 'Em atendimento', thisStatus: '2', visible: false },
    { title: 'Com interesse', thisStatus: '7', visible: false },
    { title: 'Processo de compra', thisStatus: '8', visible: false },
    { title: 'Vendido', thisStatus: '3', visible: false },
  ];

  useEffect(() => {
    if (calls !== undefined) {
      setInitialCalls(calls);
      setDataVenda(
        calls[0] ? moment(calls[0].sold_date).format('YYYY-MM-DD') : '',
      );
      setShowAddCommentary(true);
      setCallsLogs(calls_logs);
      setTitle(items);

      if (initialCalls[0]) {
        setStatus(initialCalls[0].status);
      }

      if (calls.length > 0) {
        const lastCall = calls[0];

        const discard = lastCall.discard_justification;

        setDiscardJustification(discard?.commentary);
      }
    }
  }, [calls, calls_logs]);

  const formatCPF = (e: any) => {
    const cpfname = String(e).replace(/[^\d]/g, '');
    return cpfname.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatPhone = (e: any) => {
    const cpfname = String(e).replace(/[^\d]/g, '');
    return cpfname.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  };

  const handleSelectValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const status = e.target.value;

    setSelectedStatus(status);
    setStatusUpdated(true);
    editJustificationAndAgent('status', status);
  };

  const handleUpdateAndGoBack = async (e: React.FormEvent) => {
    e.preventDefault();

    if (selectedStatus === '4' && discardJustification === '') {
      alert('Prencher Justificativa');
      setErrorsColors('errorsColors');
      setErrorsColorsSelect('errorsColorsSelect');
      return '';
    } else if (
      selectedStatus === '3' &&
      (dataVenda === 'Invalid date' || dataVenda === '')
    ) {
      alert('Prencher Data de Venda');
      setErrorsColors('errorsColors');
      setErrorsColorsSelect('errorsColorsSelect');
      return '';
    } else {
      setErrorsColors('');
      setErrorsColorsSelect('');
    }

    if (phoneNumber) {
      const formattedPhone = removeSpecialCaractersFromPhoneNumber(
        String(phoneNumber),
      );
      const existPhoneNumber = await api.get(
        `/client/admin/findPhoneNumber?phone_number=${formattedPhone}`,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );

      if (existPhoneNumber.data.response_data) {
        alert('Telefone já cadastrado');
        return '';
      }
    }

    if (cpf) {
      const existCpf = await api.get(`/client/admin/findCpf?cpf=${cpf}`, {
        headers: {
          Authorization: authToken.token,
        },
      });

      if (!cpfValidate(cpf)) {
        alert('CPF Invalido');
        return '';
      }

      if (existCpf.data.response_data) {
        alert('CPF já cadastrado');
        return '';
      }
    }

    if (permission === 'marketing') {
      return props.onHide();
    }

    if (changeDiscardJusticaiton) {
      handleUpdateDiscardJustification();
    }

    if (selectedStatus !== undefined && statusUpdated) {
      if (income !== undefined && incomeUpdated) {
        handleUpdateIncomeAndGoBack();
      }
      return handleUpdateStatusAndGoBack();
    }

    if (income !== undefined && incomeUpdated) {
      return handleUpdateIncomeAndGoBack();
    }

    if (selectedAgent !== undefined && agentUpdated) {
      return handleUpdateAgentAndGoBack();
    }

    if (updatedData) {
      return handleUpdateDataAndGoBack();
    }

    if (updatedCommentaries) {
      return props.click();
    }

    if (isUpdateEnterprise) {
      return props.click();
    }

    return props.onHide();
  };

  const handleUpdateStatusAndGoBack = async () => {
    const callId = props.last_call_id;

    if (selectedStatus !== undefined && statusUpdated) {
      const updateResult = await api.put(
        '/call/update/status',
        {
          call_id: callId,
          new_status_code: selectedStatus,
          agent_id: props.agent_name,
        },
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );

      setStatusUpdated(false);
    }

    await props.click();
  };

  async function handleUpdateAgentToRedistribute() {
    const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));
    const available = JSON.parse(sessionStorage.getItem('available'));

    await api.put(
      '/agents/update/availability',
      {
        agent_id: selectedAgent.id,
        availability: available,
        is_released: true,
      },
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );
  }
  const handleChangeDiscardJustification = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const discardJusticationOnChange = e.target.value;
    setDiscardJustification(discardJusticationOnChange);
    setChangeDiscardJustication(true);
    editJustificationAndAgent('justification', e.target.value);
  };

  async function handleChangeDateSales(e) {
    console.log(selectedStatus);
    const callId = props.last_call_id;
    console.log(dataVenda);
    if (selectedStatus === '3') {
      if (e !== '') {
        console.log('dataVenda', e, callId);

        await api
          .put(
            '/call/sales/update',
            {
              call_id: callId,
              dateVenda: e,
            },
            {
              headers: {
                Authorization: authToken.token,
              },
            },
          )
          .then((response) => {
            console.log(response.data.message);
            return toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    }
  }

  const handleUpdateDiscardJustification = async () => {
    const callId = props.last_call_id;

    if (selectedStatus === '4') {
      if (discardJustification !== '') {
        const { discard_justification } = calls[0];

        console.log('discard_justification', discard_justification);

        if (discard_justification.commentary === '') {
          await api.post(
            '/call/justification/create',
            {
              call_id: callId,
              commentary: discardJustification,
            },
            {
              headers: {
                Authorization: authToken.token,
              },
            },
          );
        } else {
          await api.put(
            '/call/justification/update',
            {
              id: discard_justification.id,
              commentary: discardJustification,
            },
            {
              headers: {
                Authorization: authToken.token,
              },
            },
          );
        }
      }
    }
  };

  const handleUpdateIncomeAndGoBack = async () => {
    const client_id = client.id;

    if (income !== undefined && incomeUpdated) {
      await api.put(
        '/client/income',
        {
          client_id,
          new_income: income,
        },
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );

      setIncomeUpdated(false);
    }

    props.click();
  };

  const handleUpdateAgentAndGoBack = async () => {
    const callId = props.last_call_id;

    if (selectedAgent !== undefined && agentUpdated) {
      await api.put(
        `call/updateAgent`,
        {
          call_id: callId,
          client_id: client.id,
          agent_id: selectedAgent,
        },
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );

      setAgentUpdated(false);
    }

    props.click();
  };

  const handleUpdateCallCommentaries = async (commentaries) => {
    const res = await api.post(
      `call/commentaries/create`,
      {
        commentaries: commentaries,
      },
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );
    return res.data.commentaries;
  };

  const handleUpdateDataAndGoBack = async () => {
    const updateResult = await api.put(
      'client/update',
      {
        id: client.id,
        full_name: fullName,
        nick_name: nickName,
        cpf: cpf,
        proposal_owner: proposalOwner,
        phone_number: phoneNumber,
        email: email,
        phone_number_2: phoneNumber2,
        email_2: email2,
      },
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    props.click();
  };

  async function editJustificationAndAgent(dado: string, e) {
    if (dado === 'agent') {
      await api.put(
        `call/updateAgent`,
        {
          call_id: calls[0].id,
          client_id: client.id,
          agent_id: e,
        },
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    } else if (dado === 'justification') {
      const { discard_justification } = calls[0];
      await api.put(
        '/call/justification/update',
        {
          id: discard_justification.id,
          commentary: discardJustification,
        },
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    } else if (dado === 'status') {
      const callId = props.last_call_id;

      await api.put(
        '/call/update/status',
        {
          call_id: callId,
          new_status_code: e,
          agent_id: props.agent_name,
        },
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    }
  }

  async function editDados(dado: string) {
    let body = {};
    if (dado === 'nick_name') {
      body = {
        id: client.id,
        nick_name: nickName,
      };
    } else if (dado === 'full_name') {
      body = {
        id: client.id,
        full_name: fullName,
      };
    } else if (dado === 'cpf') {
      body = {
        id: client.id,
        cpf: cpf,
      };
    } else if (dado === 'email') {
      body = {
        id: client.id,
        email: email,
      };
    } else if (dado === 'phone_number') {
      body = {
        id: client.id,
        phone_number: phoneNumber,
      };
    } else if (dado === 'phone_number_2') {
      body = {
        id: client.id,
        phone_number_2: phoneNumber2,
      };
    } else if (dado === 'email_2') {
      body = {
        id: client.id,
        email_2: email2,
      };
    } else if (dado === 'proposal_owner') {
      body = {
        id: client.id,
        proposal_owner: proposalOwner,
      };
    } else {
      body = {};
    }
    const updateResult = await api.put('client/update', body, {
      headers: {
        Authorization: authToken.token,
      },
    });
  }

  const handleSaveCommentaries = async (
    call_id: number,
    commentary: string,
  ) => {
    const findedCall = initialCalls.find((call) => call.id === call_id);

    if (findedCall) {
      const { callCommentaries } = findedCall;

      callCommentaries.splice(callCommentaries.length - 1, 1);

      const save = await handleUpdateCallCommentaries([
        { call_id, commentary },
      ]);

      const updatedCommentaries = [
        ...callCommentaries,
        {
          id: save[0].id,
          commentary: save[0].commentary,
          created_at_original: save[0].created_at_original,
          created_at: save[0].created_at,
        },
      ];

      let updatedCalls = initialCalls.filter((call) => call.id !== call_id);

      updatedCalls = [
        ...updatedCalls,
        {
          ...findedCall,
          callCommentaries: updatedCommentaries,
        },
      ];

      updatedCalls = updatedCalls.reverse();

      setInitialCalls(updatedCalls);
      setShowAddCommentary(true);
      setUpdatedCommentaries(true);
    }
  };

  const handleEditProposalOwner = () => {
    const initialValue = client.proposal_owner;

    setUpdatedData(true);
    setShowEditProposalOwner(!showEditProposalOwner);
    editDados('proposal_owner');
  };

  const handleEditNickName = () => {
    const initialValue = client.nick_name;

    setUpdatedData(true);
    setShowEditNickName(!showEditNickName);
    editDados('nick_name');
  };

  const handleEditFullName = () => {
    const initialValue = client.full_name;

    setUpdatedData(true);
    setShowEditFullName(!showEditFullName);
    editDados('full_name');
  };

  const handleEditCPF = () => {
    const initialValue = client.cpf;

    setUpdatedData(true);
    setShowEditCPF(!showEditCPF);
    editDados('cpf');
  };

  const handleEditPhone = () => {
    const initialValue = client.phone_number;

    setUpdatedData(true);
    setShowEditPhoneNumber(!showEditPhoneNumber);
    editDados('phone_number');
  };

  const handleEditEmail = () => {
    const initialValue = client.email;

    setUpdatedData(true);
    setShowEditEmail(!showEditEmail);
    editDados('email');
  };

  const handleEditPhone2 = () => {
    const initialValue = client.phone_number_2;

    setUpdatedData(true);
    setShowEditPhoneNumber2(!showEditPhoneNumber2);
    editDados('phone_number_2');
  };

  const handleEditEmail2 = () => {
    const initialValue = client.email_2;

    setUpdatedData(true);
    setShowEditEmail2(!showEditEmail2);
    editDados('email_2');
  };

  const handleAddCallCommentary = (call_id: number) => {
    setShowAddCommentary(false);

    const findedCall = initialCalls.find((call) => call.id === call_id);

    if (findedCall) {
      const { callCommentaries } = findedCall;

      const updatedCommentaries = [
        ...callCommentaries,
        {
          id: call_id,
          commentary: '',
          is_new: true,
        },
      ];

      let updatedCalls = initialCalls.filter((call) => call.id !== call_id);

      updatedCalls = [
        ...updatedCalls,
        {
          ...findedCall,
          callCommentaries: updatedCommentaries,
        },
      ];

      updatedCalls = updatedCalls.reverse();

      setInitialCalls(updatedCalls);
    }
  };

  const recallScore = async (callId) => {
    console.log(authToken.token);
    await api
      .put(
        `call/update/score/${callId}`,
        JSON.parse(sessionStorage.getItem('selectedAgent')),
        {
          headers: {
            authorization: authToken.token,
          },
        },
      )
      .then((response) => {
        setClassScore('btn-success');
        setErrorScore('Score atualizado com sucesso');
      })
      .catch((error) => {
        let erro;
        if (error.response.data.erroAPI.Phone) {
          setErrorsTelefone('errorsColorsSelect');
          erro = 'O número de telefone é inválido';
        } else if (error.response.data.erroAPI.Email) {
          setErrorsEmail('errorsColorsSelect');
          erro = 'O e-mail fornecido é inválido';
        } else if (error.response.data.erroAPI.document) {
          setErrorsCPF('errorsColorsSelect');
          erro = 'O CPF fornecido é inválido';
        }
        setClassScore('btn-danger');
        setErrorScore(erro);
      });
  };

  const handleViewSimulation = (simulation: any) => {
    setDataSimulation(simulation);
    setShowModalSimulation(true);
  };

  const handleSelectIndex = (index: any) => {
    setCurrentIndex(index);
    const timelineItem = items.find((element) =>
      element.title.includes(index.title),
    );
    if (timelineItem) {
      const timelineIndex = items.indexOf(timelineItem);
      const timelineStatus = callsLogs.find(
        (element) => element.status === timelineItem.thisStatus,
      );
      callStatusCodeToIndex(
        initialCalls[timelineIndex] ? initialCalls[timelineIndex].status : '',
      );
      setStatus(timelineItem.thisStatus);

      if (timelineStatus) {
        items[timelineIndex].title = `${timelineItem.title}\n ${tz(
          timelineStatus.created_at,
          'America/Sao_Paulo',
        ).format('DD/MM/YYYY HH:mm')}`;
        setTitle(items);
      } else {
        setTitle(items);
      }
    }
  };

  return (
    <ModalStyle {...props} dialogClassName="modal-90w">
      <ModalStyle.Body>
        <Row>
          <Col md="2" className="border-shadow">
            <Score color={scores?.hm_score_color}>
              <div className="itens">
                <h3>
                  Score <span>HM</span>
                </h3>
                {scores?.hm_score === undefined ? (
                  <h2>-</h2>
                ) : (
                  <h2>
                    {scores?.hm_score === 0 ? '-' : `${scores?.hm_score}`}
                  </h2>
                )}
                <span
                  className="iconRecall"
                  onClick={() => recallScore(initialCalls[0].id)}
                >
                  <ReactSVG src={calc_icon} />{' '}
                  <div className="recalcular">Recalcular Score</div>
                </span>
                <span className={`textoScore btn mt-3 ${classScore}`}>
                  {errorScore}
                </span>
              </div>
            </Score>

            <Confidence color={scores?.risk_score_color}>
              <div className="itens">
                <small>
                  Score de <span>Confiança</span>
                </small>
                {scores?.risk_score === undefined ? (
                  <h2>-</h2>
                ) : (
                  <h2 style={{ color: scores?.hm_score_color }}>
                    {scores?.risk_score === 0 ? '-' : `${scores?.risk_score}`}
                  </h2>
                )}
              </div>
            </Confidence>

            <Credit color={scores?.credit_score_color}>
              <div className="itens">
                <small>
                  Score de <span>Crédito</span>
                </small>
                {scores?.credit_score === undefined ? (
                  <h2>-</h2>
                ) : (
                  <h2 style={{ color: scores?.hm_score_color }}>
                    {scores?.credit_score === 0
                      ? '-'
                      : `${scores?.credit_score}`}
                  </h2>
                )}
              </div>
            </Credit>

            <BackContainer onClick={handleUpdateAndGoBack}>
              <div>
                <button>
                  <ReactSVG src={arrow_icon} />
                  <span>Voltar</span>
                </button>
              </div>
            </BackContainer>
          </Col>

          <Col md="5" className="body-information">
            <StatusContainer>
              <span>Status do atendimento</span>

              <select
                onChange={(e) => handleSelectValue(e)}
                value={selectedStatus}
              >
                <option value="" hidden></option>
                <option value="1">Novo</option>
                <option value="9">Retorno</option>
                <option value="6">Aguardando</option>
                <option value="2">Em atendimento</option>
                <option value="7">Com interesse</option>
                <option value="8">Processo de compra</option>
                <option value="4">Descartado</option>
                <option value="3">Vendido</option>
                {selectedStatus === '10' && (
                  <option value="10">Sem score</option>
                )}
              </select>
            </StatusContainer>

            {selectedStatus === '3' && (
              <StatusContainer>
                <span className={errorsColors}>Data da Venda</span>

                <InputData
                  type="date"
                  className={errorsColorsSelect}
                  required={selectedStatus === '3' ? true : false}
                  value={dataVenda}
                  onChange={(e) => {
                    return (
                      handleChangeDateSales(e.target.value),
                      setDataVenda(e.target.value)
                    );
                  }}
                  style={{ maxWidth: '90%' }}
                />
              </StatusContainer>
            )}

            {selectedStatus === '4' && (
              <StatusContainer>
                <span className={errorsColors}>Justificativa do descarte</span>

                <select
                  className={errorsColorsSelect}
                  required={selectedStatus === '4' ? true : false}
                  onChange={(e) => handleChangeDiscardJustification(e)}
                  value={discardJustification}
                >
                  <option value="Não reconhece a solicitação de atendimento">
                    Não reconhece a solicitação de atendimento
                  </option>
                  <option value="Não tem interesse">Não tem interesse</option>
                  <option value="Valores acima do esperado">
                    Valores acima do esperado
                  </option>
                  <option value="Região não atende à expectativa">
                    Região não atende à expectativa
                  </option>
                  <option value="Empreendimento não atende à expectativa">
                    Empreendimento não atende à expectativa
                  </option>
                  <option value="Já adquiriu">Já adquiriu</option>
                  <option value="Condições insufientes">
                    Condições insuficientes
                  </option>
                  <option value="Não está no momento de compra">
                    Não está no momento de compra
                  </option>
                  <option value="Não deseja ser contatado">
                    Não deseja ser contatado
                  </option>
                  <option value="Sem estoque">Sem estoque</option>
                  <option value="Dados inválidos">Dados inválidos</option>
                  <option value="Atendimento ao cliente">
                    Atendimento ao cliente
                  </option>
                  <option value="Não conseguimos contato">
                    Não conseguimos contato
                  </option>
                </select>
              </StatusContainer>
            )}
            {(permission === 'admin' || permission === 'admin-super') && (
              <StatusContainer style={{ borderBottom: '1px solid #FFF' }}>
                <span>Agente do atendimento</span>
                <select
                  onChange={(e) => {
                    setSelectedAgent(e.target.value);
                    setAgentUpdated(true);
                    editJustificationAndAgent('agent', e.target.value);
                  }}
                  value={agentUpdated ? selectedAgent : props.agent_name}
                >
                  {!props.data.agents.activated && (
                    <option value={props.data.agents.id}>
                      {props.agent_name} -{' '}
                      {props.data.agents.activated ? '' : 'DESATIVADO'}
                    </option>
                  )}
                  <option value="" hidden></option>
                  {selectAgent.map((agent) => (
                    <option key={agent.id} value={agent.id}>
                      {agent.full_name}
                    </option>
                  ))}
                </select>
              </StatusContainer>
            )}
            {initialCalls[0] && initialCalls[0].status !== '4' && (
              <>
                <small className="mt-3">Fluxo de atendimento</small>
                <TimeLine
                  key={initialCalls[0] ? initialCalls[0].id : ''}
                  backgroundHexColor={callStatusCodeToHexColor(
                    initialCalls[0] ? initialCalls[0].status : '',
                  )}
                >
                  <div style={{ width: '100%', marginTop: '20px' }}>
                    <Chrono
                      items={title}
                      mode="HORIZONTAL"
                      fontSizes={{
                        title: '1.2rem',
                      }}
                      disableAutoScrollOnClick
                      itemWidth={110}
                      scrollable={false}
                      activeItemIndex={callStatusCodeToIndex(
                        initialCalls[0] ? status : '',
                      )}
                      hideControls
                      onItemSelected={(e) => handleSelectIndex(e)}
                      allowDynamicUpdate={true}
                      cardLess
                    />
                  </div>
                </TimeLine>
                <br />
                <br />
              </>
            )}
            <hr />
            <ID>
              <p>
                ID de <span>Cliente</span> <strong>#{client?.id}</strong>
              </p>
            </ID>

            <Information>
              <div>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <small>Apelido</small>

                  <button
                    onClick={() => handleEditNickName()}
                    style={{ background: 'none', border: '0' }}
                  >
                    <ReactSVG
                      src={editIcon}
                      style={{
                        width: '22px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                      }}
                    />
                  </button>
                </div>

                {showEditNickName ? (
                  <h3>
                    {!client?.nick_name || client?.nick_name === ''
                      ? '-'
                      : !nickName
                      ? client?.nick_name
                      : nickName}
                  </h3>
                ) : (
                  <div>
                    <InputData
                      value={!nickName ? client?.nick_name : nickName}
                      onChange={(e) => setNickName(e.target.value)}
                      style={{ maxWidth: '90%' }}
                    />
                    {/* <button onClick={handleEditNickName}>Salvar</button>
                    <button
                      onClick={() => setShowEditNickName(!showEditNickName)}
                    >
                      Cancelar
                    </button> */}
                  </div>
                )}

                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <small>Nome</small>

                  <button
                    onClick={() => handleEditFullName()}
                    style={{ background: 'none', border: '0' }}
                  >
                    <ReactSVG
                      src={editIcon}
                      style={{
                        width: '22px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                      }}
                    />
                  </button>
                </div>

                {showEditFullName ? (
                  <h3>
                    {!client?.full_name || client?.full_name === ''
                      ? '-'
                      : !fullName
                      ? capitalizeFirstLetter(client?.full_name)
                      : fullName}
                  </h3>
                ) : (
                  <>
                    <InputData
                      value={
                        !fullName
                          ? capitalizeFirstLetter(client?.full_name)
                          : fullName
                      }
                      onChange={(e) => setFullName(e.target.value)}
                      style={{ maxWidth: '90%' }}
                    />
                    {/* <button onClick={handleEditFullName}>Salvar</button>
                    <button
                      onClick={() => setShowEditFullName(!showEditFullName)}
                    >
                      Cancelar
                    </button> */}
                  </>
                )}
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <small>CPF</small>
                  {(permission === 'admin-super' ||
                    !client?.cpf ||
                    client?.cpf === '') && (
                    <button
                      onClick={() => handleEditCPF()}
                      style={{ background: 'none', border: '0' }}
                    >
                      <ReactSVG
                        src={editIcon}
                        style={{
                          width: '22px',
                          marginLeft: '4px',
                          cursor: 'pointer',
                        }}
                      />
                    </button>
                  )}
                </div>
                {showEditCPF ? (
                  <h3 className={errorsCPF}>
                    {!client?.cpf || client?.cpf === ''
                      ? '-'
                      : !cpf
                      ? formatCPF(client?.cpf)
                      : cpf}
                  </h3>
                ) : (
                  <>
                    <InputData
                      value={!cpf ? client?.cpf : cpf}
                      onChange={(e) => setCpf(e.target.value)}
                      style={{ maxWidth: '90%' }}
                    />
                    {/* <button onClick={handleEditCPF}>Salvar</button>
                    <button onClick={() => setShowEditCPF(!showEditCPF)}>
                      Cancelar
                    </button> */}
                  </>
                )}

                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <small>Telefone</small>

                  <button
                    onClick={() => handleEditPhone()}
                    style={{ background: 'none', border: '0' }}
                  >
                    <ReactSVG
                      src={editIcon}
                      style={{
                        width: '22px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                      }}
                    />
                  </button>
                </div>

                {showEditPhoneNumber ? (
                  <h4 className={errorsTelefone}>
                    <PhoneContainer>
                      <h3>
                        {client?.phone_number === 0
                          ? '-'
                          : !phoneNumber
                          ? formatPhone(client?.phone_number)
                          : phoneNumber}
                      </h3>
                      <PhoneVerified>
                        {client?.verified_phone ? (
                          <ReactSVG src={check_icon} />
                        ) : (
                          <ReactSVG src={help_icon} />
                        )}
                      </PhoneVerified>
                    </PhoneContainer>
                  </h4>
                ) : (
                  <>
                    <IMaskInput
                      name="phone_number"
                      required
                      mask="(00) 00000-0000"
                      value={
                        !String(phoneNumber)
                          ? formatPhone(client?.phone_number)
                          : String(phoneNumber)
                      }
                      unmask
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="fieldStyle"
                    />
                    {/* <button onClick={handleEditPhone}>Salvar</button>
                    <button
                      onClick={() =>
                        setShowEditPhoneNumber(!showEditPhoneNumber)
                      }
                    >
                      Cancelar
                    </button> */}
                  </>
                )}

                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <small>E-mail</small>

                  <button
                    onClick={() => handleEditEmail()}
                    style={{ background: 'none', border: '0' }}
                  >
                    <ReactSVG
                      src={editIcon}
                      style={{
                        width: '22px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                      }}
                    />
                  </button>
                </div>

                {showEditEmail ? (
                  <h4
                    className={errorsEmail}
                    style={{ textTransform: 'lowercase' }}
                  >
                    {!client?.email || client?.email === ''
                      ? '-'
                      : client?.email}
                  </h4>
                ) : (
                  <>
                    <InputData
                      value={!email ? client?.email : email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {/* <button onClick={handleEditEmail}>Salvar</button>
                    <button onClick={() => setShowEditEmail(!showEditEmail)}>
                      Cancelar
                    </button> */}
                  </>
                )}

                {client?.phone_number !== 0 && (
                  <a
                    target="_blank"
                    href={`https://wa.me/55${client?.phone_number}`}
                    rel="noopener noreferrer"
                  >
                    <Whatsapp>
                      <ReactSVG src={whatsapp_icon} />
                      <span> Enviar mensagem no WhatsApp</span>
                    </Whatsapp>
                  </a>
                )}
              </div>

              <div>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <small>Proponente</small>

                  <button
                    onClick={() => handleEditProposalOwner()}
                    style={{ background: 'none', border: '0' }}
                  >
                    <ReactSVG
                      src={editIcon}
                      style={{
                        width: '22px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                      }}
                    />
                  </button>
                </div>

                {showEditProposalOwner ? (
                  <h4>
                    {!client?.proposal_owner || client?.proposal_owner === ''
                      ? '-'
                      : client?.proposal_owner}
                  </h4>
                ) : (
                  <>
                    <InputData
                      value={
                        !proposalOwner ? client?.proposal_owner : proposalOwner
                      }
                      onChange={(e) => setProposalOwner(e.target.value)}
                    />
                    {/* <button onClick={handleEditProposalOwner}>Salvar</button>
                    <button
                      onClick={() =>
                        setShowEditProposalOwner(!showEditProposalOwner)
                      }
                    >
                      Cancelar
                    </button> */}
                  </>
                )}

                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <small>Telefone 2</small>

                  <button
                    onClick={() => handleEditPhone2()}
                    style={{ background: 'none', border: '0' }}
                  >
                    <ReactSVG
                      src={editIcon}
                      style={{
                        width: '22px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                      }}
                    />
                  </button>
                </div>

                {showEditPhoneNumber2 ? (
                  <h4>
                    {!client?.phone_number_2 || client?.phone_number_2 === ''
                      ? '-'
                      : formatPhone(client?.phone_number_2)}
                  </h4>
                ) : (
                  <>
                    <IMaskInput
                      name="phone_number"
                      required
                      mask="(00) 00000-0000"
                      value={
                        !phoneNumber2
                          ? formatPhone(client?.phone_number_2)
                          : phoneNumber2
                      }
                      unmask
                      onChange={(e) => setPhoneNumber2(e.target.value)}
                      className="fieldStyle"
                    />
                    {/* <button onClick={handleEditPhone2}>Salvar</button>
                    <button
                      onClick={() =>
                        setShowEditPhoneNumber2(!showEditPhoneNumber2)
                      }
                    >
                      Cancelar
                    </button> */}
                  </>
                )}

                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <small>E-mail 2</small>

                  <button
                    onClick={() => handleEditEmail2()}
                    style={{ background: 'none', border: '0' }}
                  >
                    <ReactSVG
                      src={editIcon}
                      style={{
                        width: '22px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                      }}
                    />
                  </button>
                </div>

                {showEditEmail2 ? (
                  <h4 style={{ textTransform: 'lowercase' }}>
                    {!client?.email_2 || client?.email_2 === ''
                      ? '-'
                      : client?.email_2}
                  </h4>
                ) : (
                  <>
                    <InputData
                      value={!email2 ? client?.email_2 : email2}
                      onChange={(e) => setEmail2(e.target.value)}
                    />
                    {/* <button onClick={handleEditEmail2}>Salvar</button>
                    <button onClick={() => setShowEditEmail2(!showEditEmail2)}>
                      Cancelar
                    </button> */}
                  </>
                )}
              </div>
            </Information>
          </Col>
          <Col md="5" className="attendance">
            <CallsContainer>
              {initialCalls.map((item: any) => (
                <Attendance
                  key={item.id}
                  backgroundHexColor={callStatusCodeToHexColor(item.status)}
                >
                  <div className="groupitens">
                    <div className="attendance-id">
                      <p>
                        ID de <span>Atendimento</span>{' '}
                        <strong>#{item.id}</strong>
                      </p>
                    </div>
                    <div className="btn-date">
                      <ReactSVG src={date_icon} />
                      <span>{item.call_date}</span>
                    </div>
                  </div>
                  {item.origin !== '' && (
                    <>
                      <small className="origin-label">Origem</small>
                      <div className="origin-info">
                        <span>{item.origin}</span>
                      </div>
                    </>
                  )}
                  {item.status !== null && item.status !== '' && (
                    <>
                      <small className="origin-label">Status</small>
                      <div className="call-status-info">
                        <span>{statusCodeToStatusLabel(item.status)}</span>
                      </div>
                    </>
                  )}

                  <div className="information-itens">
                    <small>Empreendimento</small>
                    <SelectEnterprise
                      enterpriseName={item.name}
                      enterpriselist={props.enterpriselist}
                      editIcon={editIcon}
                      callId={item.id}
                      setIsUpdateEnterprise={setIsUpdateEnterprise}
                    />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.enterprise_url}
                    >
                      + <span>Acessar página</span>
                    </a>
                  </div>

                  <div className="information-itens">
                    <small>Cidade</small>
                    <h3>{item.city}</h3>
                  </div>

                  <div className="information-itens">
                    <small className="tipoAtendimento">
                      Tipo do atendimento
                    </small>

                    {scores?.hm_score === 0 ? (
                      <span className="no-call-type">-</span>
                    ) : item.call_type === 'Agendar visita' ? (
                      <div className="btn-orange-back">
                        <div className="btn-orange">{item.call_type}</div>
                      </div>
                    ) : item.origin === 'agenda' ? (
                      <div className="btn-green-back">
                        <div className="btn-green">{item.origin}</div>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="btn-blue-back">
                          <div className="btn-blue">{item.call_type}</div>
                        </div>
                        {item.simulation && (
                          <div className="btn-view-simulation">
                            <button
                              onClick={() =>
                                handleViewSimulation(item.simulation)
                              }
                            >
                              Ver simulação
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="information-itens">
                    <small>Renda mensal</small>
                    <IntlCurrencyInput
                      value={incomeUpdated ? income : item.monthly_income}
                      inputMode="numeric"
                      style={{
                        color: 'black',
                        width: '50%',
                        fontSize: '2.4rem',
                        fontWeight: '600',
                        marginBottom: '0',
                        border: 'none',
                      }}
                      currency="BRL"
                      config={{
                        locale: 'pt-BR',
                        formats: {
                          number: {
                            BRL: {
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          },
                        },
                      }}
                      onChange={(
                        event: React.FormEvent,
                        value: string,
                        _maskedValue: any,
                      ) => {
                        event.preventDefault();
                        setIncome(Number(value));
                        setIncomeUpdated(true);
                      }}
                    />
                  </div>
                  {item.call_type !== 'Agendar visita' && (
                    <div className="information-itens">
                      <small>Financiamento compartilhado</small>
                      <h3>{item.shared_financing ? 'Sim' : 'Não'}</h3>
                    </div>
                  )}

                  <small
                    style={{
                      fontSize: '1.6rem',
                      fontWeight: 600,
                      display: 'block',
                      marginBottom: '1rem',
                    }}
                  >
                    Comentários
                  </small>

                  {item.callCommentaries &&
                    item.callCommentaries.map((item, index) => (
                      <React.Fragment key={index}>
                        {!showAddCommentary && item.is_new && (
                          <small style={{ fontWeight: 600 }}>
                            Novo comentário
                          </small>
                        )}
                        <CallCommentary
                          call_id={item.id}
                          initialCommentary={item.commentary}
                          created_at={item.created_at}
                          is_new={item.is_new ? item.is_new : false}
                          callBackHandleSaveCommentary={handleSaveCommentaries}
                        />
                      </React.Fragment>
                    ))}

                  {showAddCommentary && permission !== 'marketing' && (
                    <AddCommentary
                      onClick={() => handleAddCallCommentary(item.id)}
                    >
                      + Adicionar comentário
                    </AddCommentary>
                  )}
                </Attendance>
              ))}
            </CallsContainer>
          </Col>
          <Col
            md="5"
            style={{
              position: 'fixed',
              top: '0.2rem',
              right: '0.5rem',
              display: showModalSimulation ? 'flex' : 'none',
            }}
          >
            <ModalFinancing>
              <div className="header-simulation">
                <button
                  onClick={() => {
                    setShowModalSimulation(false);
                  }}
                >
                  X
                </button>
                <span>
                  <strong>Simulação de financiamento</strong>
                </span>
                <span></span>
              </div>
              <div className="content-simulation">
                <div>
                  <span className="label">FGTS:</span>
                  <span className="values">
                    <span className={dataSimulation.fgts ? 'yes' : 'no'}>
                      {dataSimulation.fgts ? 'SIM' : 'NÃO'}
                    </span>
                  </span>
                </div>
                <div>
                  <span className="label">Entrada FGTS:</span>
                  <span className="values">
                    {dataSimulation.value_fgts
                      ? Number(dataSimulation.value_fgts).toLocaleString(
                          'pt-br',
                          { style: 'currency', currency: 'BRL' },
                        )
                      : ''}
                  </span>
                </div>
                <div>
                  <span className="label">Financiamento Compartilhado:</span>
                  <span className="values">
                    <span className={dataSimulation.is_shared ? 'yes' : 'no'}>
                      {dataSimulation.is_shared ? 'SIM' : 'NÃO'}
                    </span>
                  </span>
                </div>
                <div>
                  <span className="label">Entrada Recursos Próprios:</span>
                  <span className="values">
                    {dataSimulation.input_value
                      ? Number(dataSimulation.input_value).toLocaleString(
                          'pt-br',
                          { style: 'currency', currency: 'BRL' },
                        )
                      : ''}
                  </span>
                </div>
                <div>
                  <span className="label">Taxa Anual:</span>
                  <span className="values">{dataSimulation.annual_rate} %</span>
                </div>
                <div>
                  <span className="label">Renda:</span>
                  <span className="values">
                    {dataSimulation.min_income
                      ? Number(dataSimulation.min_income).toLocaleString(
                          'pt-br',
                          { style: 'currency', currency: 'BRL' },
                        )
                      : ''}
                  </span>
                </div>
                <div>
                  <span className="label">Valor Máximo da Parcela:</span>
                  <span className="values">
                    {dataSimulation.max_installment
                      ? Number(dataSimulation.max_installment).toLocaleString(
                          'pt-br',
                          { style: 'currency', currency: 'BRL' },
                        )
                      : ''}
                  </span>
                </div>
                <div>
                  <span className="label">Valor Financiado:</span>
                  <span className="values">
                    {dataSimulation.financing_value
                      ? Number(dataSimulation.financing_value).toLocaleString(
                          'pt-br',
                          { style: 'currency', currency: 'BRL' },
                        )
                      : ''}
                  </span>
                </div>
                {/* <div>
                  <span className="label">Valor Parcela:</span>
                  <span className="values">
                    {dataSimulation.installment_value
                      ? Number(dataSimulation.installment_value).toLocaleString(
                          'pt-br',
                          { style: 'currency', currency: 'BRL' },
                        )
                      : ''}
                  </span>
                </div> */}
                <div>
                  <span className="label">Prazo:</span>
                  <span className="values">
                    {dataSimulation.deadline} parcelas
                  </span>
                </div>
              </div>
            </ModalFinancing>
          </Col>
        </Row>
      </ModalStyle.Body>
    </ModalStyle>
  );
};

export default ModalElement;
